import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Dimensionality`}</h1>
    <ul>
      <li parentName="ul">{`Dimensionality is a measure of the number of `}<a parentName="li" {...{
          "href": "/dimensions",
          "title": "dimensions"
        }}>{`dimensions`}</a>{` of a space. The dimensionality of a piece of paper is 2 (2d), the dimensionality of your kitchen is 3 (3d)`}</li>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "/dimensionality",
          "title": "dimensionality"
        }}>{`dimensionality`}</a>{` of a `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{` `}<a parentName="li" {...{
          "href": "/conversation",
          "title": "conversation"
        }}>{`conversation`}</a>{` is n `}<a parentName="li" {...{
          "href": "/dimensions",
          "title": "dimensions"
        }}>{`dimensions`}</a>{`, where n is equal to the number of `}<a parentName="li" {...{
          "href": "/comments",
          "title": "comments"
        }}>{`comments`}</a>{` in the `}<a parentName="li" {...{
          "href": "/conversation",
          "title": "conversation"
        }}>{`conversation`}</a>{``}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      